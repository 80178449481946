.centered {
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
}

.non-centered {
  text-align: none;
}
.pad-bot {
  padding-bottom: 3rem;
}
/* Footer */

.footer-logo {
  margin-top: -3rem;
}

.icon-footer {
  font-size: 30px;
}

/* Price Page */
.h-price {
  height: 100.3%;
}
.price-table__list {
  height: 170px;
}
.nav-effect {
  cursor: pointer;
  position: relative;
  padding: 5px;

  font-size: 22px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  transition: all 1s;
  &:after,
  &:before {
    content: " ";
    width: 10px;
    height: 10px;
    position: absolute;
    border: 0px solid #fff;
    transition: all 1s;
  }
  &:after {
    top: -1px;
    left: -1px;
    border-top: 5px solid black;
    border-left: 5px solid black;
  }
  &:before {
    bottom: -1px;
    right: -1px;
    border-bottom: 5px solid black;
    border-right: 5px solid black;
  }
  &:hover {
    border-top-right-radius: 0px;
    border-bottom-left-radius: 0px;
    background: rgba(0, 0, 0, 0.5);
    color: white;
    &:before,
    &:after {
      width: 100%;
      height: 100%;
      border-color: white;
    }
  }
}

.tp-about-right {
  text-align: center;
}
